




































































































import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import ScoringSystem from '@/components/ScoringSystem.vue'
import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import { InputSetups } from '../../../mixins/input-setups'
import TariffsTagsHelper from '../../../mixins/TariffsTagsHelper'
import Placeholders from '@/mixins/placeholders/placeholders'

import { MediaEditorMode } from 'piramis-base-components/src/components/MessageEditorWithMedia/types'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import ExtendedHelpMessage from 'piramis-base-components/src/components/ExtendedHelpMessage.vue'

import { Component, Mixins } from 'vue-property-decorator'
import { Guid } from 'guid-typescript'
import CurrentModule from "@/components/Modules/components/CurrentModule.vue";
import TempCurrentModuleMixin from "@/includes/logic/Modules/mixins/TempCurrentModuleMixin";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";

@Component({
  'components': {
    CurrentModule,
    NotAvailableOptionsOverlay,
    CenteredColumnLayout,
    ScoringSystem,
    PageTitle,
    ExtendedHelpMessage
  },
  data() {
    return {
      MediaEditorMode,
    }
  }
})
export default class RankSystem extends Mixins<UseFields, InputSetups, TariffsTagsHelper, Placeholders, TempCurrentModuleMixin>(UseFields, InputSetups, TariffsTagsHelper, Placeholders, TempCurrentModuleMixin) {
  get frontendRanks(): Array<any> {
    return this.$store.state.chatState.chat.config.user_ranks
      .map((rank:any, index:number, arr:Array<any>) => {
        if (index === 0) {
          return { key: Guid.create().toString(), xp: null, reputation: null, title: rank.title }
        } else {
          return {
            key: Guid.create().toString(),
            xp: arr[index - 1].xp,
            reputation: arr[index - 1].reputation,
            title: rank.title,
          }
        }
      })
  }

  configRanks(ranks: Array<any>): Array<any> {
    return ranks.slice()
      .map((rank:any, index:number, arr:Array<any>) => {
        if (index === arr.length - 1) {
          return {
            xp: Number.MAX_SAFE_INTEGER,
            reputation: Number.MAX_SAFE_INTEGER,
            title: rank.title,
          }
        } else {
          return {
            xp: arr[index + 1].xp,
            reputation: arr[index + 1].reputation,
            title: rank.title,
          }
        }
      })
  }

  onRanksChange(ranks: Array<any>) {
    this.$store.commit('pi/EXEC', {
      'fn': () => {
        this.$store.state.chatState.chat.config.user_ranks = this.configRanks(ranks)
      }
    })
  }

  created() {
    this.setCurrentModule(ModuleTypesEnum.RanksHandlerModule)
  }
}
